@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    /* font-family: 'IBM Plex Sans', sans-serif!important; */
    margin: 0;
    padding: 0;
}

*::selection {
    background: #d21dd3;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 7px;
    z-index: 100;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #444444;
    border-radius: 0px;
    z-index: 100;
    border: none;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
    z-index: 100;
}

*::-webkit-scrollbar-thumb:active {
    background-color: #666666;
    z-index: 100;
}

#searchbody {
    background-repeat: no-repeat;
    height: 100%;
    background-position: center;
    background-size: cover;
    position: fixed;
}

#windowsetting {
    z-index: 35 !important;
    position: position !important;
    display: fixed;
}